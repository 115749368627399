import React from "react";
// import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SeoHelper";
import Pagination from "../components/Pagination";
import CategoryBlocks from "../components/CategoryBlocks";
// import BlogCard from "../components/BlogCard";
import DisplayPosts from "../components/DisplayPosts";
import Sidebar from "../components/Sidebar";
// import { useCategories } from "../hooks/useCategories";
import routes from "../routes";
import Container from "../components/Container";
import BreadCrumb from "../components/BreadCrumb";
// import { categoryToSlug } from "../common/toSlug";
import { useTheme } from "../components/ThemeContext";

// eslint-disable-next-line
const IndexPage = ({ pageContext: { index, pathPrefix, pageCount, group } }) => {
  const title = "Welcome to our blog";
  const seoTitle = "Blog";
  const { theme } = useTheme();

  return (
    <Layout>
      <Container>
        <SEO
          title={`${seoTitle} ${index !== 1 ? `| Page ${index.toString()}` : ""}`}
          description={`${theme.title} is here to help you learn and discover the best things out there.`}
        />

        <header>
          <BreadCrumb arrayOfLinkNameValues={[{ name: "Blog", link: routes.blog }]} />
          <h1 className="mb-4 text-xl font-bold md:text-3xl">
            {title} {index !== 1 && <>Page {index}</>}
          </h1>
        </header>

        <div className="flex flex-wrap">
          <div className="w-full md:w-3/4 md:pr-16 lg:pr-24">
            <DisplayPosts posts={group} columns="3" />
          </div>
          <div className="w-full md:w-1/4">
            <Sidebar />
          </div>
        </div>
        <div className="text-center">
          <Pagination pageCount={pageCount} index={index} pathPrefix={pathPrefix} />
        </div>
        <footer>
          <h2 className="mb-2 text-xl font-bold md:text-2xl md:mb-4">Visit other categories</h2>
          <CategoryBlocks />
        </footer>
      </Container>
    </Layout>
  );
};
export default IndexPage;
